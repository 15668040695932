.sidebar {
  width: 240px;
  background-color: #2c3e50;
  color: white;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}

.logo {
  padding: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.logo h2 {
  margin: 0;
  font-size: 1.5rem;
}

.sidebar nav {
  display: flex;
  flex-direction: column;
}

.sidebar nav a {
  color: white;
  text-decoration: none;
  padding: 0.75rem 1rem;
  margin: 0.25rem 0;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.sidebar nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar nav a.active {
  background-color: rgba(255, 255, 255, 0.2);
} 