.app {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.main-content {
  flex-grow: 1;
  margin-left: 240px; /* Desktop sidebar width */
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 0;
  transition: margin-left 0.3s ease;
}

.main-content-wrapper {
  padding: 16px;
  max-width: 100%;
  overflow-x: hidden;
}

/* AdminSelector styles */
.admin-selector {
  margin-bottom: 16px;
  padding: 0 8px; /* Add consistent padding */
}

/* Responsive styles */
@media (max-width: 900px) {
  .main-content {
    margin-left: 0;
    width: 100%;
  }

  .main-content-wrapper {
    padding: 12px;
  }

  /* Adjust grid items for tablets */
  .grid-item {
    flex-basis: 50% !important;
    max-width: 50% !important;
  }
}

/* Small devices */
@media (max-width: 600px) {
  .main-content-wrapper {
    padding: 8px;
  }

  .admin-selector {
    padding: 0 4px;
  }

  /* Stack grid items on mobile */
  .grid-item {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  /* Adjust card layouts for mobile */
  .mobile-card {
    margin-bottom: 8px;
  }

  /* Make tables responsive */
  .responsive-table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
 